import React from 'react';
  
const OglebayInvitational = () => {
  return (
    <div>
      <h1 class="ogbText">MBGolfers Invitational at Oglebay</h1>
    </div>
  );
};
  
export default OglebayInvitational;