import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { db } from "../firebase-config";
import { collection, getDocs, query} from "firebase/firestore"

const Courses2024 = () => {
  const bannerCollectionRef = collection(db, "Banner");
  const [bannerData, setBannerData] = useState([]);

  useEffect(() =>{
    const getBanner = async () => {
      const qb = query(bannerCollectionRef);
      const banners = await getDocs(qb);
      setBannerData(banners.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    getBanner().then(function(){
      console.log("get banner ran");
      console.log(bannerData);
    })

  }, []);


  let courseArray;
  const [courseData, setCourseData] = useState([]);
  const getCourses = async () =>{
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vSqFDtFYMD8iipm4W1o02-y-1Jr0EwpduihmLMxnNCKUAABRkJ_E_p_Zco7UczbNQ/pub?gid=479822605&single=true&output=csv", {
      download: true,
      header: true,
      complete: (results) => {
  
        setCourseData(results.data)
      },
    });
  }

  useEffect(() =>{
    getCourses();
    console.log("get courses ran")
  }, []);

  useEffect(() =>{
    console.log("course data updated")
    console.log(courseData)
  }, [courseData])


  return (
    <div class="marqueeDiv">
    {/* <marquee class="bannerMessage">{bannerData[0]?.message}</marquee> */}
    <div class="mainContent">
      <h1>2024 Courses</h1>
      <h4>Courses are displayed in alphabetical order.<br></br>
        Information can be sorted on any of the columns.<br></br>
        Goal is to play at least 55 different courses during the 2024 season.   <br></br>
        <br></br>
        To view the 2023 Courses list, click <a href="./courses2023">here</a>.
      </h4>
        <table class="coursesTable">
          <thead>
            <tr>
              <th>Number</th>
              <th>Course Name</th>
              <th>City</th>
              <th>Region</th>
              <th>Digital Golf Pass Offer?</th>
              <th>Date</th>
              <th>Times Played</th>
            </tr>
          </thead>
          <tbody>
            {courseData.map(event =>{
              return (
                <tr key={event.number}>
                  <td class="numberData">{event.number}</td>
                  <td>{event.course}</td>
                  <td>{event.city}</td>
                  <td>{event.region}</td>
                  <td>{event.pass}</td>
                  <td>{event.date}</td>
                  <td class="numberData">{event.timesPlayed}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
    </div>
    </div>
  );
};

export default Courses2024;