import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, updateDoc, doc, where, query, orderBy, arrayRemove, deleteDoc, addDoc, limit } from "firebase/firestore";
import DatePicker from "react-datepicker";

  
const SFX_Event = () => {

  const [editLoc, setEditLoc] = useState("");
  const [editDate, setEditDate] = useState(new Date());
  const [editNotes, setEditNotes] = useState("");
  const [userName, setUserName] = useState("");
  const [userNumber, setUserNumber] = useState("")
  const [editNumber, setEditNumber] = useState("")
  const [editUser, setEditUser] = useState("")
  const [eventName, setEventName] = useState("");
  const [location, setLocation] = useState("");
  const [notes, setNotes] = useState("");
  const [date, setDate] = useState(new Date());
  const [PPTT, setPPTT] = useState(0);

  const [usersList, setUsersList] = useState([]);
  const [teeTimes, setTeeTimes ] = useState([]);
  const slotList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
  const teetimeCollectionRef = collection(db, "TeeTimes");
  const sfxUsersCollectionRef = collection(db, "sfxUsers");
  const timeSlots = ["3:00 PM", "3:09 PM", "3:18PM"];
  const [inputName, setInputName] = useState("");
  const startOfDay = new Date();
  let userAcc = false;
  let editAcc = false;
  let addAcc = false;
  startOfDay.setUTCHours(0,0,0,0);
  let eventTime;
  let nameCheck="";
  let nameSwitch=false;
  let timeVar;

  const updateLocation = async (id) =>{
    const eventDoc = doc(db, "TeeTimes", id);
    if(editLoc !== ""){
      await updateDoc(eventDoc, {
        Location: editLoc
      })
      window.location.reload();
    }
  }

  const updateDate = async (id) =>{
    const eventDoc = doc(db, "TeeTimes", id);
      await updateDoc(eventDoc, {
        Date: editDate
      })
      window.location.reload();
  }

  const updateNotes = async (id) =>{
    const eventDoc = doc(db, "TeeTimes", id);
    await updateDoc(eventDoc, {
      Notes: editNotes
    })
    window.location.reload();
  }

  const deleteEvent = async (id) => {
    const eventDoc = doc(db, "TeeTimes", id);
    await deleteDoc(eventDoc);
    window.location.reload();
  }

  const createTeeTime = async () =>{
    if(eventName !== "" && location !== ""){
        document.getElementById("fieldError").style.display = "none"
        await addDoc(teetimeCollectionRef, {Date: date, Location: location, NumberPerTeeTime: (Number(PPTT)+1), Name: eventName, Notes: notes, Slots: {0: ["3:00 PM"], 1: ["3:09 PM"], 2: ["3:18 PM"], 3: ["3:27 PM"],
        4: ["3:36 PM"], 5: ["3:45 PM"], 6: ["3:54 PM"], 7: ["4:03 PM"], 8: ["4:12 PM"], 9: ["4:21 PM"], 10: ["4:30 PM"], 11: ["4:39 PM"],
        12: ["4:48 PM"], 13:["4:57 PM"], 14: ["5:06 PM"], 15: ["5:15 PM"], 16: ["5:24 PM"], 17: ["5:33 PM"], Players: []
    }})
        window.location.reload();
    }else{
        document.getElementById("fieldError").style.display = "block";
    }

}

  const addSfxUser = async () => {
    let testname
    let testemail
    if(userName !== "" && userNumber !== ""){
      document.getElementById("userError").style.display = "none";
      document.getElementById("usersuccess").style.display = "block";
      // for(let i=0; i<usersData.length; i++){
      //   testname=usersData[i].name;
      //   testemail=usersData[i].email;
      //   await addDoc(usersCollectionRef, {name: testname, email: testemail})
      // }
      await addDoc(sfxUsersCollectionRef, {name: userName, phoneNumber: userNumber})
    }else{
      document.getElementById("userError").style.display = "block";
      document.getElementById("usersuccess").style.display = "none";
    }
  }

  const updateUser = async (id) => {
    if(editUser != ""){
      const userdoc = doc(db, "sfxUsers", id);
      await updateDoc(userdoc, {
        name: editUser
      })
    }
  }

  const updateNumber = async (id) => {
    if(editNumber != ""){
      const userdoc = doc(db, "sfxUsers", id);
      await updateDoc(userdoc, {
        phoneNumber: editNumber
      })
    }
  }

  const deleteUser = async (id) => {
    const eventdoc = doc(db, "sfxUsers", id);
    await deleteDoc(eventdoc);
    window.location.reload();
  }

  useEffect(() =>{
    const getSFXUsers = async () => {
      const qw = query(sfxUsersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data(), id: doc.id})))
    }; 
 
    getSFXUsers().then(function(){
    })
  }, [])
  
  useEffect(() =>{

    const getTeeTimes = async () =>{
      const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"), limit(1));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 

    getTeeTimes(); 
  }, []);

  const checkTeeTime = async (time) =>{
    let tempData=[];
    const getTeeTimes = async () =>{
      const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"), limit(1));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addUser(tempData[0].id, time, tempData[0].Slots["Players"], tempData[0].Slots[time], tempData[0], tempData[0].Slots[time].length)
    };
    getTeeTimes()
    console.log(teeTimes);
  }

  const checkForDelete = async (time) =>{
    let tempData=[];
    const getTeeTimes = async () =>{
      const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"), limit(1));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      removeUser(tempData[0].id, time, tempData[0].Slots["Players"], tempData[0].Slots[time], tempData[0], tempData[0].Slots[time].length)
    };
    getTeeTimes()
    console.log(teeTimes);
  }

  const addUser = async (id, time, players, teetimeGolfers, timeSlots, slotLength) =>{
    const teetimeDoc= doc(db, "TeeTimes", id);    
    let shownName=""
    if(slotLength<teeTimes[0].NumberPerTeeTime){
      if(inputName === ""){
        document.getElementById("text"+time).style.display = "block"
      }else{
        for(let i=0; i<usersList.length; i++){
          nameCheck = usersList[i].phoneNumber;
          if(nameCheck === inputName){
            nameSwitch=true;
            shownName= usersList[i].name;
          }
        }if(!players.includes(shownName)){
          if(nameSwitch===true){
            const teeTimePlayer = timeSlots;
            teeTimePlayer.Slots[time] = [...teetimeGolfers, shownName];
            teeTimePlayer.Slots["Players"] = [...players, shownName];
            await updateDoc(teetimeDoc, teeTimePlayer);
            document.getElementById("text"+time).style.display = "none";
            document.getElementById("error"+time).style.display = "none";
            document.getElementById("duplicate"+time).style.display = "none";
            document.getElementById("success"+time).style.display = "block";
            document.getElementById(time+'input').value = "";
            window.location.reload();
          }else{
            document.getElementById("error"+time).style.display = "block";
          }
        }else{
          document.getElementById("duplicate"+time).style.display = "block";
        }
      }
    }else{
      document.getElementById("checkFailure"+time).style.display = "block";
    }
  }

  const removeUser = async (id, time, players, teetimeGolfers, timeSlots) => {

    let shownName="";
    let updatedList = [];
    let updatedPlayers = [];
    if(inputName === ""){
      document.getElementById("text"+time).style.display = "block"
    }else{
      for(let i=0; i<usersList.length; i++){
        nameCheck = usersList[i].phoneNumber;
        if(nameCheck === inputName){
          nameSwitch=true;
          shownName= usersList[i].name;
        }
      }if(players.includes(shownName)){
        if(nameSwitch===true){
          const updatedSlots = timeSlots
          updatedList = teetimeGolfers;
          var slotIndex = updatedList.indexOf(shownName)
          if(slotIndex !== -1){
            updatedList.splice(slotIndex, 1);
            updatedPlayers = players;
            var playersIndex = updatedPlayers.indexOf(shownName)
            if(playersIndex !== -1){
              updatedPlayers.splice(playersIndex, 1)
            }
            updatedSlots.Slots[time] = updatedList;
            updatedSlots.Slots["Players"] = updatedPlayers;
            const teetimeDoc = doc(db, "TeeTimes", id);
            await updateDoc(teetimeDoc, updatedSlots);
            document.getElementById("text"+time).style.display = "none";
            document.getElementById("error"+time).style.display = "none";
            document.getElementById("notPresent"+time).style.display = "none";
            document.getElementById("timeError"+time).style.display = "none";
            document.getElementById("removeSuccess"+time).style.display = "block";
            document.getElementById(time+'input').value = "";
            const getEvents = async () =>{
              const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"), limit (1));
              const newData = await getDocs(q);
              setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
            };
            setInputName("");
            getEvents();
          }else{
            document.getElementById("timeError"+time).style.display = "block";
          }
        }else{
          document.getElementById("error"+time).style.display = "block";
        }
      }else{
        document.getElementById("notPresent"+time).style.display = "block";
      }
    }
    
  }


  const userAccButton = () => {
    userAcc = !userAcc
    if(userAcc){
      document.getElementById("usersAccordion").style.display = "block"
    }else{
      document.getElementById("usersAccordion").style.display = "none"
    }
  }

  const addAccButton = () => {
    addAcc = !addAcc
    if(addAcc){
      document.getElementById("addAccordion").style.display = "block"
    }else{
      document.getElementById("addAccordion").style.display = "none"
    }
  }

  const editAccButton = () => {
    editAcc = !editAcc
    if(editAcc){
      document.getElementById("editAccordion").style.display = "block"
    }else{
      document.getElementById("editAccordion").style.display = "none"
    }
  }


  return (

    <div class="mainContent">
        <div class="accButtonDiv">
        <button class="accordionButton" onClick={userAccButton}>Add/Edit Users</button>
            <div class="accordion" id="usersAccordion" style={{display: "none"}}>
                <h1>Add Users</h1>
                <div class="addEventdiv" style={{width:"80%"}}>
                <input class="addEvent" placeholder="user name" onChange={(event)=> {setUserName(event.target.value)}}/>
                <input class="addEvent" placeholder="phone number" onChange={(event)=> {setUserNumber(event.target.value)}}/>
                <h2 id="userError" style={{display: "none", color: "red"}}>Name and Phone Number need a value</h2>
                <button onClick={addSfxUser}> Add User </button>
                <h3 id={'usersuccess'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User Added!</h3>
                <ul class="userList">
                    {usersList.map((user) =>(
                    <li key={user.id}>
                        {user.name} : {user.phoneNumber} <button onClick={() =>{deleteUser(user.id)}} style={{backgroundColor: "red", color: "white"}}>Delete User</button>
                        <br></br>
                        <input placeholder="Edit Name" onChange={(event)=>{setEditUser(event.target.value)}}></input>
                        <button onClick={()=>{updateUser(user.id)}}>Edit Name</button>
                        <br></br>
                        <input placeholder="Edit Phone Number" onChange={(event)=>{setEditNumber(event.target.value)}}></input>
                        <button onClick={()=>{updateNumber(user.id)}}>Edit Phone Number</button>
                    </li>
                    ))}
                </ul>
                </div>
            </div>
            <button class="accordionButton" onClick={addAccButton}>Add Events</button>
      <div class="accordion" id="addAccordion" style={{display: "none"}}>
        <h1>Add Events</h1>
        <div class="addEventdiv">
            <input class="addEvent" placeholder="Event Name" onChange={(event)=>{
                setEventName(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" placeholder="Location" onChange={(event)=>{
                setLocation(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" type="number" placeholder="Players per Tee Time" onChange={(event)=>{
                setPPTT(event.target.value);
            }}/>
            <br></br>
            <input class="addEvent" placeholder="Notes" onChange={(event)=>{
                setNotes(event.target.value);
            }}/>
            <br></br>
            <DatePicker 
                showTimeInput
                dateFormat="MMMM d, yyyy h:mmaa"
            selected={date} onChange={date=> setDate(date)} />
            <br></br>
            <button onClick={createTeeTime}>Add Event</button>
            <h2 id="fieldError" style={{display: "none", color: "red"}}>EVENT NAME, LOCATION, MAX PARTICIPANTS AND ATTENDEES MUST BE FILLED OUT</h2>
        </div>
      </div>
        <button class="accordionButton" onClick={editAccButton}>Edit Events</button>
        <div class="accordion" id="editAccordion" style={{display: "none"}}>
                <ul class="eventList">
                    {teeTimes?.map((teetime) => (
                        <li key={teetime.id}>
                        <div class="eventDiv">
                            <div class="eventHeader">
                            <h2>{teetime.Name}</h2>
                            <h4 class="loc">{teetime.Location}</h4>
                            <input class="eventEdit" placeholder="Edit Location" onChange={(e) =>{setEditLoc(e.target.value)}}></input>
                            <button class="editButton" onClick={()=>{updateLocation(teetime.id)}}>Edit Event Location</button>
                            <h4>
                                {new Date(teetime.Date.seconds * 1000 + teetime.Date.nanoseconds / 1000000).toDateString()}<br></br>
                                {eventTime = teetime.Date.toDate().toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}
                            </h4>
                            <DatePicker 
                                showTimeInput
                                dateFormat="MMMM d, yyyy h:mmaa"
                                selected={editDate} onChange={date=> setEditDate(date)} />
                                <button class="editButton" onClick={()=>{updateDate(teetime.id)}}>Edit Event Date</button>
                            </div>
                            <h4>Tee Times:</h4>
                            <div class="eventContent">
                            <ul class="timeSlotList">
                                {slotList.map((value)=>(
                                <div class="timeSlotDiv">
                                    {teetime.Slots[value].map((slot, index)=>(
                                    <li key={index}>
                                        <p class={'timeSlotLI'+index}>{slot}</p>
                                    </li>
                                    ))}
                                    {(() =>{
                                    if ((teetime.NumberPerTeeTime > teetime.Slots[value].length)){
                                        return (
                                          <div style={{display: "flex", flexDirection: "column"}}>
                                            <input id={value+'input'} class="attendeeInput" placeholder="Phone Number" onChange={(e) =>{setInputName(e.target.value)}}/>
                                            <h3 id={'text'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Phone Number</h3>
                                            <h3 id={'timeError'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is signed up for a different time</h3>
                                            <h3 id={'notPresent'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is not signed up</h3>
                                            <h3 id={'duplicate'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                            <h5 id={'error'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Phone Number entered was not recognized. <br></br>
                                            If this was a mistake, contact Mike Baumgartner.</h5>
                                            <br></br>
                                            <button class="sfxButton" onClick={() =>{checkTeeTime(value)}}>Sign Up</button>
                                            <br></br>
                                            <button class="sfxButton" id={"red"} onClick={() =>{checkForDelete(value)}}>Remove User</button>
                                            <h3 id={'success'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                                            <h3 id={'removeSuccess'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User has been removed</h3>
                                            <h3 id={'checkFailure'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                                          </div>
                                        )
                                    }else{
                                    return(
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                          <h3 style={{color: 'red', fontSize: '1.4em'}}>Tee Time is Full</h3>
                                          <input id={value+'input'} class="attendeeInput" placeholder="Phone Number" onChange={(e) =>{setInputName(e.target.value)}}/>
                                          <h3 id={'text'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Phone Number</h3>
                                          <h3 id={'timeError'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is signed up for a different time</h3>
                                          <h3 id={'notPresent'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is not signed up</h3>
                                          <h3 id={'duplicate'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                          <h5 id={'error'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Phone Number entered was not recognized. <br></br>
                                          If this was a mistake, contact Mike Baumgartner.</h5>
                                          <button class="sfxButton" id={"red"} onClick={() =>{checkForDelete(value)}}>Remove User</button>
                                          <h3 id={'success'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                                          <h3 id={'removeSuccess'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User has been removed</h3>
                                          <h3 id={'checkFailure'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                                        </div>
                                    )
                                    }
                                    })()}
                                </div>
                                ))}
                            </ul>
                            </div>
                            <div class="notesDiv">
                                <h4 class="notesText">Additional Notes:<br></br>{teetime.Notes}</h4>
                                <input style={{margin: "0 auto", marginBottom: "10px"}} class="eventEdit" placeholder="Edit Notes" onChange={(e)=>{setEditNotes(e.target.value)}}></input>
                                <button class="editButton" onClick={()=>{updateNotes(teetime.id)}}>Edit Notes</button>
                                <button id={teetime.id+'deleteEvent'} class="deleteEvent" onClick={() =>{deleteEvent(teetime.id)}}>Delete Event</button>
                            </div>
                        </div>
                        </li>
                    ))}
                    </ul>
            </div>
        </div>
    </div>
  );
};
  
export default SFX_Event;