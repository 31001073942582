import React from 'react';
import MemberPic from "./OgbImages/FoundingMembers.jpg"
  
const Overview = () => {
  return (
    <div class="mainContent">
      <h1>Overview</h1>
      <p class="overviewText">
      The MBGolfers.com Invitational at Oglebay was started in 2008 with the goal of bringing family and friends together for good times golfing, 
      eating great food, playing cards and cornhole, and solving the problems of the world.  <br></br><br></br>
      The first event was held in May 2008 with eight (8) brave men in the ‘scouting group’ to try out the location and determine if this should be an annual event.
      The group consisted of Jim (Dad), Uncle Dwight, Uncle Dave, Uncle John, Alan (Doc), Steve, Gregg, and Mike… this group is known as our <b>Founding Members</b>. 
      </p>
      <img id="memberPic" src={MemberPic} alt="The Founding Members"></img>
      <p class="overviewText">
      The first year was only 3 days and 2 nights… but we jammed a lot of golf into that short time playing 18-holes the first day, 36-holes the second day, 
      and 18-holes the third day.  Everyone had a great time and we decided to make this annual event. <br></br><br></br>
      Over the years, many changes have been made to improve the event.  We expanded the number of participants… added days (up to 6-days at the peak)… 
      switched from May to September to get the best possible weather… and added lots of fun golf games to keep our rounds interesting.<br></br><br></br>
      More than 50 players have participated in the Oglebay Invitational since it’s inception.  
      Some of our members have passed on to the fairways in heaven and others are no longer able to play due to health issues, 
      but the friendships and memories from this event will live on in the hearts of all our participants for years to come.
      </p>
    </div>
  );
};
  
export default Overview;