import React, { useState, useEffect } from "react";
import Papa from "papaparse";

const Event_Details = () => {


  const today = new Date();
  const fullDate = (today.getMonth() + 1)+ "/" + today.getDate()
  const [golfData, setGolfData] = useState([]);
  const [lodgingData, setLodgingData] = useState([]);
  const [payoutData, setPayoutData] = useState([]);
  const [payoutHead, setPayoutHead] = useState([]);

  const getEventData = async () =>{
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vSmt-pgytXn68SzLDHFbCz0Qaqf-W4dkLeMZZ4ffs3jt0mVFGOJGJ4g8m_XAaRaeg/pub?output=csv", {
      download: true,
      header: true,
      complete: (results) => {
        
        setGolfData(results.data.slice(4,29))
        setLodgingData(results.data.slice(33,58))
      },
    },
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vSNyJUwpaPhZFeaICnZJl5iLNLlljlgUJ12MlzDpanglPCpuuWYSea6Wz10mL2lNQ/pub?gid=73169492&single=true&output=csv", {
      download: true,
      header: true,
      complete: (results) =>{
        setPayoutData(results.data)
      }
    })

    );
  }

  useEffect(() =>{
    getEventData();
  }, []);

  useEffect(() =>{
    console.log("golf data updated")
    console.log(golfData)
  }, [golfData])

  useEffect(() =>{
    console.log("payout data updated")
    console.log(payoutData)
  }, [payoutData])

  useEffect(() =>{
    console.log("lodging data updated")
    console.log(lodgingData)
  }, [lodgingData])

  return (
    <div class="mainContent" style={{padding: "0px"}}>
      <h1>2023 Event Details</h1>
      <div class="buttonNav">
        <a href="#TeeTime"><button class="detailsButton">Tee Time Info / Golf Rules</button></a>
        <a href="#GolfParticipants"><button class="detailsButton">Golf / Lodging by Day</button></a>
        <a href="#Schedule"><button class="detailsButton">Tentative Schedule</button></a>
        <a href="#PlannedGames"><button class="detailsButton">Planned Games / Payout Info</button></a>
        <a href="#ProvidedList"><button class="detailsButton">What's Provided / What to Bring</button></a>
      </div>
      
      <div id="TeeTime" style={{paddingTop: "100px"}}>
        <h3>Tee Time Info</h3>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Course</th>
              <th colSpan={6}>Tee Times</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sunday, September 10th</td>
              <td>Palmer</td>
              <td>2:40 PM</td>
              <td>2:50 PM</td>
              <td>3:00 PM</td>
              <td>3:10 PM</td>
              <td>3:20 PM</td>
              <td>3:30 PM</td>
            </tr>
            <tr>
              <td>Monday, September 11th</td>
              <td>Jones</td>
              <td>9:33 AM</td>
              <td>9:42 AM</td>
              <td>9:51 AM</td>
              <td>10:00 AM</td>
              <td>10:09 AM</td>
              <td>10:36 AM</td>
            </tr>
            <tr>
              <td>Tuesday, September 12th</td>
              <td>Palmer</td>
              <td>8:50 AM</td>
              <td>9:00 AM</td>
              <td>9:10 AM</td>
              <td>9:20 AM</td>
              <td>9:30 AM</td>
              <td>9:40 AM</td>
            </tr>
            <tr>
              <td>Wednesday, September 13th</td>
              <td>Jones</td>
              <td>9:06 AM</td>
              <td>9:15 AM</td>
              <td>9:24 AM</td>
              <td>9:33 AM</td>
              <td>9:42 AM</td>
            </tr>
            <tr>
              <td>Thursday, September 14th</td>
              <td>Palmer</td>
              <td>11:50 AM</td>
              <td>12:00 PM</td>
              <td>12:10 PM</td>
              <td>12:20 PM</td>
            </tr>
          </tbody>
        </table>
        <div style={{paddingTop: "30px"}}>
          <h3>Golf Rules</h3>
          <p class="overviewText">
          The entire purpose of the MBGolfers Invitational at Oglebay is to have a great time playing golf with friends.  
          To that end, we try to make the rules as generous and as fair as possible to maximize fun and minimize pain and frustration while 
          still being consistent among all our groups since prize money is on the line.
          </p>
          <ul class="gamesList">
            <li>
              <h5>Tees</h5>
            We recommend playing from each of the tees based on your age (see table below):
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Tees</th>
                </tr>
                <tr>
                  <th>Ages</th>
                  <th>Color</th>
                  <th>Approx. Yardage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>80 & Over </td>
                  <td>Red</td>
                  <td>4,600</td>
                </tr>
                <tr>
                  <td>70 - 79 </td>
                  <td>Gold</td>
                  <td>5,300</td>
                </tr>
                <tr>
                  <td>Under 70 </td>
                  <td>White</td>
                  <td>6,000</td>
                </tr>
              </tbody>
            </table>
            </li>
            <li>
              <h5>Winter Rules</h5>
            We will play ‘winter rules’ which allows you to ‘lift, clean, and place’ your ball no nearer the hole when in <b>your own fairway. </b> 
            If your ball is in the rough, you must play the ball as it lies unless you are in an area that is considered ‘ground under repair’.  
            If we have bad weather, we may change this rule to allow ‘lift, clean, and place’ in the rough.  <br></br><br></br>
            For those {"≥"} 70 years old, you are allowed to ‘lift, clean, and place’ your ball everywhere (in the fairways, rough, etc. but no nearer the hole).
            </li>
            <li>
              <h5>Breakfast Ball</h5>
            Each player has one ‘breakfast ball’ that they are allowed to utilize on their first tee-shot of the day.  
            If you decide to hit a breakfast ball, you must play that ball (i.e. this is not ‘take the best shot’ of the two that you hit).  
            Also, if you don’t use your breakfast ball, it does not carry forward to another time in the round.
            </li>
            <li>
              <h5>Mulligans</h5>
            No mulligans allowed unless you are {"≥"} 70 years old.   Those {"≥"} 80 years old receive two (2) mulligan shots that can be utilized 
            anytime during their round; those {"≥"} 70 - 79 years old receive one (1) mulligan shot that can be utilized anytime during their round… 
            This is in addition to the Breakfast Ball rule above which can only be used on the first tee. <br></br><br></br>
            If you are under 70 years old… sorry, you have something to look forward to.
            </li>
            <li>
              <h5>Putts</h5>
            For consistency and since we have prize money on the line, putts may be given to others in your group <b>only</b> if the ball is ‘inside the leather’
             of the putter.  Inside the leather for our group is defined as “from the bottom of the putter to where the grip starts” which is 2-feet or less.  
            </li>
            <li>
              <h5>Sand Traps</h5>
            Since the conditions of sand traps can vary significantly, you may lift your ball, rake the area, and then place the ball in the raked 
            area to play your next shot.  However, if your ball is plugged in its own pitch mark, you must play the plugged lie (fried egg) without raking the 
            trap first. <br></br> <br></br>If you have taken two (2) shots in the same trap and are still in the bunker, you may lift the ball and place it 
            alongside the trap for your next shot… no penalty stroke (you and your playing companions have incurred enough pain).
            </li>
            <li>
              <h5>Out-of-bounds</h5>
              Play from the location that the ball went out of bounds with a 1-stroke penalty (drop within 2 club lengths)  or you can go back and play from 
              the location that you last hit the ball (i.e. stroke and distance)... player choice. 
            </li>
            <li>
              <h5>Water Hazard</h5>
              If there is a ‘drop-area’, take a penalty stroke and play from that location.  If no ‘drop-area’, keep the point where you entered the hazard 
              and drop anywhere back on a straight-line no nearer the hole; or replay your shot from the previous location.<br></br><br></br>
              If your next shot goes into the same water hazard, you may proceed to the other side of the water hazard and play your next stroke 
              (i.e. two ball maximum in the water hazard on a hole; this is to make certain that we don’t overflow the ponds).
            </li>
            <li>
              <h5>Lost Ball</h5>
              If you lose a ball in the fairway or mowed rough due to plugging or just can’t find it after 3 minutes, you can drop and play another ball 
              from the location that you believe your ball should be with <b>no penalty stroke.</b> <br></br><br></br>
              If your ball is in the high grass that is not mowed (i.e. hillside, ravines, etc.) and you cannot find your ball, you are allowed to drop 
              a ball at the nearest point of entry and play with a <b>one stroke penalty.</b> 
            </li>
            <li>
              <h5>Safety First</h5>
              We all hit shots that might end up in tough locations on steep hills, deep bunkers, on tree roots, etc.… please go by the safety first 
              motto… we don’t want anyone getting injured hitting a shot.  Feel free to move the ball to a safer location for your next shot.    
              You can determine if you should take a penalty stroke for the movement… let your conscience be your guide.
            </li>
          </ul>
        </div>
      </div>
      <div id="GolfParticipants" style={{paddingTop: "100px"}}>
        <h3>Golf Participants by Day</h3>
        <p style={{textAlign: "center"}}>A green box denotes particiaption</p>
        <table class="golfPTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Sunday, Sep 10</th>
              <th>Monday, Sep 11</th>
              <th>Tuesday, Sep 12</th>
              <th>Wednesday, Sep 13</th>
              <th>Thursday, Sep 14</th>
            </tr>
          </thead>
          <tbody>
            {golfData.map(person =>{
              return (
                <tr>
                  <td>{person._1}</td>
                  <td class={'test'+person._2}></td>
                  <td class={'test'+person._3}></td>
                  <td class={'test'+person._4}></td>
                  <td class={'test'+person._5}></td>
                  <td class={'test'+person._6}></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div id="LodgingParticipants" style={{paddingTop: "30px"}}>
        <h3>Lodging by Day</h3>
        <p style={{textAlign: "center"}}>A green box denotes particiaption</p>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Sunday, Sep 10</th>
              <th>Monday, Sep 11</th>
              <th>Tuesday, Sep 12</th>
              <th>Wednesday, Sep 13</th>
            </tr>
          </thead>
          <tbody>
            {lodgingData.map(person =>{
              return (
                <tr>
                  <td>{person._1}</td>
                  <td class={'test'+person._2}></td>
                  <td class={'test'+person._3}></td>
                  <td class={'test'+person._4}></td>
                  <td class={'test'+person._5}></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div id="Schedule" style={{paddingTop: "100px"}}>
        <h3>Tentative Schedule</h3>
        <table>
          <thead>
            <tr>
              <th>Day</th>
              <th>Time</th>
              <th>Activity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={2} style={{textAlign: "center"}}>Sunday</td>
              <td>2:15 PM</td>
              <td>Meet at Palmer Course for 2:40 Tee Time (we are hoping to tee off early if possible)</td>
            </tr>
            <tr>
              <td>8:00 PM</td>
              <td>Supper at Chambers Cottage and distribution of prize money</td>
            </tr>
            <tr>
              <td rowSpan={3} style={{textAlign: "center"}}>Monday</td>
              <td>6:45 - 7:45 AM</td>
              <td>Breakfast Grill open at Chambers Cottage</td>
            </tr>
            <tr>
              <td>9:10 AM</td>
              <td>Group Photo at Starters Building (near putting greens); first tee-time starts at 9:33 AM</td>
            </tr>
            <tr>
              <td>6:00 PM</td>
              <td>Supper at Chambers Cottage and distribution of prize money</td>
            </tr>
            <tr>
              <td rowSpan={4} style={{textAlign: "center"}}>Tuesday</td>
              <td>6:45 - 7:45 AM</td>
              <td>Breakfast Grill open at Chambers Cottage</td>
            </tr>
            <tr>
              <td>8:30 AM</td>
              <td>Group Photo (back up if bad weather on Monday) at Starters Building; first tee-time starts at 8:50 AM</td>
            </tr>
            <tr>
              <td>2:00 PM</td>
              <td>Distribution of prize money prior to some people departing</td>
            </tr>
            <tr>
              <td>6:00 PM</td>
              <td>Supper at Chambers Cottage</td>
            </tr>
            <tr>
              <td rowSpan={4} style={{textAlign: "center"}}>Wednesday</td>
              <td>6:45 - 7:45 AM</td>
              <td>Breakfast Grill open at Chambers Cottage</td>
            </tr>
            <tr>
              <td>8:45 AM</td>
              <td>Group Photo at Starters Building; first tee-time starts at 9:06 AM</td>
            </tr>
            <tr>
              <td>2:00 PM</td>
              <td>Distribution of prize money prior to some people departing</td>
            </tr>
            <tr>
              <td>6:00 PM</td>
              <td>Supper at Chambers Cottage and distribution of prize money</td>
            </tr>
            <tr>
              <td rowSpan={3} style={{textAlign: "center"}}>Thursday</td>
              <td>7:00 - 8:00 AM</td>
              <td>Breakfast Grill open at Chambers Cottage</td>
            </tr>
            <tr>
              <td>11:30 AM</td>
              <td>Group Photo (back-up if bad weather on Wednesday) at Starters Building; first tee time starts at 11:50 AM</td>
            </tr>
            <tr>
              <td>5:00 PM</td>
              <td>Supper at Speidel Golf Course Grill and distribution of prize money</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="PlannedGames" style={{paddingTop: "100px"}}>
        <h3>Planned Games</h3>
        <p class="overviewText">Each day of the week, we have various games that we will play.  
        The cost / day is $7.50.  Please pay for all the days you are playing on the first day of Oglebay 
        (or you can pay earlier via PayPal or Zelle).  If something happens and you are not able to play one of the days, your money will be refunded to you.</p>
        <ul class="gamesList">
          <li>
            <h5>Daily Skins (Gross) - $1 / Round</h5>
            <p>Total pot will be divided by the number of gross skins for the day.  In the event that no skins are won, each player will get their entrance fee returned.</p>
          </li>
          <li>
            <h5>Closet to the Pin (Par 3's) - $1.50 / Round</h5>
            <p>Total pot will be divided by the winners of the closest pin shots (on the green) for each of the par 3 holes. The ball must be on the green to win.</p>
          </li>
          <li>
            <h5>Team Game (3-man blind draw; low 2 scores out of 3 / hole; Front 9 team total, Back 9 team total, 18 hole total) - 
              $3 / Round ($1 Front nine, $1 Back Nine, $1 Total)</h5>
            <p>Teams selected after the round is completed via a blind draw.  
              Each team will have one player that scored in the top third for the round, one player that scored in the middle third for the round, and one player 
              that scored in the lower third for the round. <br></br><br></br>The lowest two scores of the three team members will be counted for each hole.  
              Each team will have a total front nine score, back nine score, and total score.<br></br><br></br>The pot will be evenly split (1/3 for the front; 
              1/3 for the back; and 1/3 for total). <br></br><br></br>
              If there are 4 teams or less, only 1st place will be paid for front, back, and totals.  For 5 teams or more, 1st place will receive 75% of the pot and 2nd place will receive 25% for front, back, and totals. </p>
              In the event that the total number of golfers is not divisible by three (3); a player will be selected via blind draw to fill out the last team.  
              This player will need to contribute an additional $3 if they wish to participate in any winnings that the team would receive.  
              If the selected player(s) don’t wish to contribute the $3, then anyone else at the event is allowed to pay the $3 and they are then eligible to receive 
              their portion of the prize money awarded to the team.
          </li>
          <li>
            <h5>Scheid Handicap - $1 / Round</h5>
            <p>
            I have no idea how to explain this game… Gregg’s computer will figure out the winner(s).<br></br><br></br>
For 9 players or less, only 1st place will be paid; for 10 - 16 players, 1st and 2nd place will be paid; and for 17 or more players, 1st, 2nd, and 3rd place will be paid.

            </p>
          </li>
          <li>
            <h5>Weekly Skins (Gross) - $1 / Round</h5>
            <p>Each player contributes $1 for each round played during the week.  The total pot will be split based on the total number of skins (single lowest score on a hole for all rounds) for the week on both the Jones and Palmer courses.  Note: a player cannot cancel out their own skin if they have the two lowest scores on a given hole for the week.
</p>
          </li>
        </ul>
      </div>
      <div style={{paddingTop: "30px"}}>
          <h3>Payout Info</h3>
          <table>
            <thead>
              <tr>
                <th colSpan={6}>Weekly skins (as of {fullDate})</th>
                {console.log(fullDate)}
              </tr>
            </thead>
            <tbody>
            <tr>
                <th colSpan={1}>Hole #</th>
                <th colSpan={2} style={{backgroundColor: "#ffffcc"}}>Palmer</th>
                <th colSpan={1}>Hole #</th>
                <th colSpan={2} style={{backgroundColor: "#a6bade"}}>Jones</th>
            </tr>
            {payoutData.map(round =>{
              return (
                <tr>
                  <td>{round.HoleNumber}</td>
                  <td>{round.PalmerScore}</td>
                  <td class="green" id={round.PalmerName}>{round.PalmerName}</td>
                  <td>{round.HoleNumber2}</td>
                  <td>{round.JonesScore}</td>
                  <td class="green" id={round.JonesName}>{round.JonesName}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
      </div>
      <div id="ProvidedList" style={{paddingTop: "100px", paddingBottom: "20px"}}>
        <h3>What's Provided / What to Bring</h3>
        <ul style={{width: "60%", margin: "0 auto"}}>
          <h5>What's Provided</h5>
          <li>All food for breakfast and supper</li>
          <li>Bedding and towels</li>
          <li>Water, Arnold Palmer, Milk, Coffee, Cream, Orange Juice</li>
        </ul>
        <ul style={{width: "60%", margin: "0 auto"}}>
          <h5>What to Bring</h5>
          <li>Snack to share</li>
          <li>Any drinks that aren't listed above (i.e. beer, bourbon, pop)</li>
          <li>Lawn chair (optional)</li>
          <li>Sunscreen</li>
          <li>Cards, cornhole, games</li>
          <li>GOLF CLUBS</li>
        </ul>
        <p></p>
      </div>
    </div>
  );
};
  
export default Event_Details;