import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, updateDoc, doc, where, query, orderBy } from "firebase/firestore"
import moment from "moment";
  
const CharityEvents = () => {

  const [events, setEvents] = useState([]);
  const charCollectionRef = collection(db, "CharityEvents");
  let eventTime;
  const startOfDay = new Date();
  startOfDay.setUTCHours(0,0,0,0);
  
  useEffect(() =>{

    const getEvents = async () =>{
      const q = query(charCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setEvents(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 

    getEvents().then(function(){
      console.log("get events function done")
    }); 

  }, []);


  return (
    <div class="marqueeDiv">
    <div class="mainContent">
      <h1>Charity Events</h1>
      <h4>To sign up for an event, follow the link provided in the event below.</h4>
      <ul class="eventList">
        {events?.map((event, index) => (
            <li key={event.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{event.Name}</h2>
                </div>
                <div class="eventHeader" style={{justifyContent: 'spaceBetween'}}>
                  <h4 class="loc">{event.Location}</h4>
                  <h4>
                    {new Date(event.Date.seconds * 1000 + event.Date.nanoseconds / 1000000).toDateString()}<br></br>
                  </h4>
                </div>
                <div style={{margin: '0 auto', margin: '40px'}}>
                    <p>{event.Info}</p>
                </div>
                <div class="inputDiv">
                    <a href={event.Link} target="_blank" class="linkButton">
                        View Event
                    </a>
                </div>
              </div>
            </li>
          ))}
        </ul>

    </div>
    </div>
  );
};
  
export default CharityEvents;