import React, { useState } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

function Navbar2() {

  const [eventVisible, setEventVisible] = useState(false);

  const eventClick = () => {
    setEventVisible(!eventVisible);
    console.log(eventVisible);
  }

  const EventDetailsMenu = () => {
    return(
      <div>
        <ul>
          <li>
            <a class="coursesNav" href="/oglebay/signup_2025">2025 Signup</a>
          </li>
          <li>
            <a class="coursesNav" href="/oglebay/event_details2024">2024 Event Details</a>
          </li>
        </ul>
      </div>
    )
  }

  const [overviewVis, setOverviewVis] = useState(false);

  const overviewClick = () => {
    setOverviewVis(!overviewVis);
    console.log(overviewVis);
  }

  const OverviewMenu = () => {
    return(
      <div>
        <ul>
          <li>
            <a class="coursesNav" href="/oglebay/overview">Overview</a>
          </li>
          <li>
            <a class="coursesNav" href="/oglebay/hall_of_fame">Hall of Fame</a>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <nav class="oglebayNav">
      <ul>
        <li>
          <a href="/">
            <div class="navLogo"></div>
          </a>
        </li>
        <li class="coursesLi">
          <div class="dropdownMenu ogbDropdown">
            <a onClick={overviewClick}>Overview / HOF</a>
            {overviewVis && <OverviewMenu />}
          </div>
        </li>
        <li class="coursesLi">
          <div class="dropdownMenu ogbDropdown">
            <a onClick={eventClick}>Event Details</a>
            {eventVisible && <EventDetailsMenu />}
          </div>
        </li>
        <li>
          <a href="/oglebay/past_participants">
            Past Roster
          </a>
        </li>
        <li>
          <a href="/oglebay/gallery">
            Gallery
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar2;