import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../../firebase-config";
import { collection, getDocs, updateDoc, doc, where, query, orderBy, arrayRemove, limit } from "firebase/firestore"
  
const Signup2025 = () => {

  const [usersList, setUsersList] = useState([]);
  const [teeTimes, setTeeTimes ] = useState([]);
  const SignupsCollectionRef = collection(db, "Oglebay2025Signups");
  const UsersCollectionRef = collection(db, "users");
  const [inputName, setInputName] = useState("");
  const startOfDay = new Date();
  const [mondayGolf, setMondayGolf] = useState(false);
  const [sundayGolf, setSundayGolf] = useState(false);
  const [tuesdayGolf, setTuesdayGolf] = useState(false);
  const [wednesdayGolf, setWednesdayGolf] = useState(false);
  const [thursdayGolf, setThursdayGolf] = useState(false);
  const [fridayGolf, setFridayGolf] = useState(false);
  const [mondayLodge, setMondayLodge] = useState(false);
  const [sundayLodge, setSundayLodge] = useState(false);
  const [tuesdayLodge, setTuesdayLodge] = useState(false);
  const [wednesdayLodge, setWednesdayLodge] = useState(false);
  const [thursdayLodge, setThursdayLodge] = useState(false);
  startOfDay.setUTCHours(0,0,0,0);
  let eventTime;
  let nameCheck="";
  let nameSwitch=false;
  let timeVar;


  useEffect(() =>{
    const getUsers = async () => {
      const qw = query(UsersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data()})))
    }
    getUsers();
  }, [])

  useEffect(() =>{

    const getSignups = async () =>{
      const q = query(SignupsCollectionRef);
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 
    getSignups()
    console.log(teeTimes)
    console.log("get signups ran")

  }, []);

  const checkSignup = async (id, index, time) =>{
    let tempData=[];
    const getSignups = async () =>{
      const q = query(SignupsCollectionRef);
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      signUp(tempData[0].id, tempData[0].attendees, tempData[0])
    };
    getSignups()
    console.log(teeTimes);
  }

  // const checkForDelete = async (id, index, time) =>{
  //   let tempData=[];
  //   const getSignups = async () =>{
  //     const q = query(OgbSignupsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
  //     const newData = await getDocs(q);
  //     setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
  //     tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
  //     removeUser(tempData[index].id, time, tempData[index].Slots["Players"], tempData[index].Slots[time], tempData[index], tempData[index].Slots[time].length)
  //   };
  //   getSignups()
  //   console.log(teeTimes);
  // }

  const signUp = async (id, attendees, signupData) => {
    const signupDoc= doc(db, "Oglebay2025Signups", id);   
    let shownName="" 
      if(inputName === ""){
      }else{
        for(let i=0; i<usersList.length; i++){
          nameCheck = usersList[i].email;
          if(nameCheck === inputName.toLowerCase()){
            console.log("yes, name is " + usersList[i].name);
            nameSwitch=true;
            shownName= usersList[i].name;
          }
        }
        if(!attendees.includes(shownName)){
          if(nameSwitch===true){
            const newSignup = signupData;
            if(sundayGolf===true){
              newSignup.sundayGolf = [...signupData.sundayGolf, shownName]
            }
            if(mondayGolf===true){
              newSignup.mondayGolf = [...signupData.mondayGolf, shownName]
            }
            if(tuesdayGolf===true){
              newSignup.tuesdayGolf = [...signupData.tuesdayGolf, shownName]
            }
            if(wednesdayGolf===true){
              newSignup.wednesdayGolf = [...signupData.wednesdayGolf, shownName]
            }
            if(thursdayGolf===true){
              newSignup.thursdayGolf = [...signupData.thursdayGolf, shownName]
            }
            if(fridayGolf===true){
              newSignup.fridayGolf = [...signupData.fridayGolf, shownName]
            }
            if(sundayLodge===true){
              newSignup.sundayLodge = [...signupData.sundayLodge, shownName]
            }
            if(mondayLodge===true){
              newSignup.mondayLodge = [...signupData.mondayLodge, shownName]
            }
            if(tuesdayLodge===true){
              newSignup.tuesdayLodge = [...signupData.tuesdayLodge, shownName]
            }
            if(wednesdayLodge===true){
              newSignup.wednesdayLodge = [...signupData.wednesdayLodge, shownName]
            }
            if(thursdayLodge===true){
              newSignup.thursdayLodge = [...signupData.thursdayLodge, shownName]
            }
            newSignup.attendees = [...signupData.attendees, shownName]
            await updateDoc(signupDoc, newSignup);
            document.getElementById("emailtext").style.display = "none";
            document.getElementById("emailduplicate").style.display = "none";
            document.getElementById("submitsuccess").style.display = "block";
            // document.getElementById(time+'input').value = "";
            window.location.reload();
          }
        }else{
          document.getElementById("emailduplicate").style.display = "block";
        }
      }
  }

  // const addUser = async (id, time, players, teetimeGolfers, timeSlots, slotLength, index) =>{
  //   const teetimeDoc= doc(db, "Oglebay2025Signups", id);   
  //   let shownName="" 
  //   if(slotLength<teeTimes[0].NumberPerTeeTime){
  //     if(inputName === ""){
  //       document.getElementById("text"+time).style.display = "block"
  //     }else{
  //       for(let i=0; i<usersList.length; i++){
  //         nameCheck = usersList[i].email;
  //         if(nameCheck === inputName.toLowerCase()){
  //           console.log("yes, name is " + usersList[i].name);
  //           nameSwitch=true;
  //           shownName= usersList[i].name;
  //         }
  //       }
  //       if(!players.includes(shownName)){
  //         if(nameSwitch===true){
  //           const teeTimePlayer = timeSlots;
  //           console.log(timeSlots)
  //           teeTimePlayer.Slots[time] = [...teetimeGolfers, shownName];
  //           teeTimePlayer.Slots["Players"] = [...players, shownName];
  //           await updateDoc(teetimeDoc, teeTimePlayer);
  //           document.getElementById("text"+time).style.display = "none";
  //           document.getElementById("duplicate"+time).style.display = "none";
  //           document.getElementById("success"+time).style.display = "block";
  //           document.getElementById(time+'input').value = "";
  //           window.location.reload();
  //         }
  //       }else{
  //         document.getElementById("duplicate"+time).style.display = "block";
  //       }
  //     }
  //   }else{
  //     document.getElementById("checkFailure"+time).style.display = "block";
  //   }
  // }


  // const removeUser = async (id, time, players, teetimeGolfers, timeSlots) => {
  //   let shownName="";
  //   let updatedList = [];
  //   let updatedPlayers = [];
  //   if(inputName === ""){
  //     document.getElementById("text"+time).style.display = "block"
  //   }else{
  //     for(let i=0; i<usersList.length; i++){
  //       nameCheck = usersList[i].email;
  //       if(nameCheck === inputName.toLowerCase()){
  //         console.log("yes, name is " + usersList[i].name);
  //         nameSwitch=true;
  //         shownName= usersList[i].name;
  //       }
  //     }
  //     if(players.includes(shownName)){
  //       if(nameSwitch===true){
  //         const updatedSlots = timeSlots
  //         updatedList = teetimeGolfers;
  //         var slotIndex = updatedList.indexOf(shownName)
  //         if(slotIndex !== -1){
  //           updatedList.splice(slotIndex, 1);
  //           updatedPlayers = players;
  //           var playersIndex = updatedPlayers.indexOf(shownName)
  //           if(playersIndex !== -1){
  //             updatedPlayers.splice(playersIndex, 1)
  //           }
  //           updatedSlots.Slots[time] = updatedList;
  //           updatedSlots.Slots["Players"] = updatedPlayers;
  //           const teetimeDoc = doc(db, "Oglbebay2025Signups", id);
  //           await updateDoc(teetimeDoc, updatedSlots);
  //           document.getElementById("text"+time).style.display = "none";
  //           document.getElementById("notPresent"+time).style.display = "none";
  //           document.getElementById("timeError"+time).style.display = "none";
  //           document.getElementById("removeSuccess"+time).style.display = "block";
  //           document.getElementById(time+'input').value = "";
  //           const getEvents = async () =>{
  //             const q = query(OgbSignupsCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
  //             const newData = await getDocs(q);
  //             setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
  //           };
  //           setInputName("");
  //           getEvents();
  //         }else{
  //           document.getElementById("timeError"+time).style.display = "block";
  //         }
  //       }
  //     }else{
  //       document.getElementById("notPresent"+time).style.display = "block";
  //     }
  //   }
    
    // await updateDoc(teetimeDoc, {
    //   Slots: arrayRemove(user)
    // })
  

  const sundayGChange = () => {
    console.log(sundayGolf)
    setSundayGolf(!sundayGolf)
  }

  const mondayGChange = () => {
    setMondayGolf(!mondayGolf)
    console.log(mondayGolf)
  }

  const tuesdayGChange = () => {
    console.log(tuesdayGolf)
    setTuesdayGolf(!tuesdayGolf)
  }

  const wednesdayGChange = () => {
    setWednesdayGolf(!wednesdayGolf)
    console.log(wednesdayGolf)
  }

  const thursdayGChange = () => {
    console.log(thursdayGolf)
    setThursdayGolf(!thursdayGolf)
  }

  const fridayGChange = () => {
    setFridayGolf(!fridayGolf)
    console.log(fridayGolf)
  }

  const sundayLChange = () => {
    console.log(sundayLodge)
    setSundayLodge(!sundayLodge)
  }

  const mondayLChange = () => {
    setMondayLodge(!mondayLodge)
    console.log(mondayLodge)
  }

  const tuesdayLChange = () => {
    console.log(tuesdayLodge)
    setTuesdayLodge(!tuesdayLodge)
  }

  const wednesdayLChange = () => {
    setWednesdayLodge(!wednesdayLodge)
    console.log(wednesdayLodge)
  }

  const thursdayLChange = () => {
    console.log(thursdayLodge)
    setThursdayLodge(!thursdayLodge)
  }

  const toggleSundayG = () => {
    if(document.getElementById("sundayGToggle").style.display = "none"){
      document.getElementById("sundayGToggle").style.display = "block"
    }
  }

  const toggleMondayG = () => {
    if(document.getElementById("mondayGToggle").style.display = "none"){
      document.getElementById("mondayGToggle").style.display = "block"
    }
  }

  const toggleTuesdayG = () => {
    if(document.getElementById("tuesdayGToggle").style.display = "none"){
      document.getElementById("tuesdayGToggle").style.display = "block"
    }
  }

  const toggleWednesdayG = () => {
    if(document.getElementById("wednesdayGToggle").style.display = "none"){
      document.getElementById("wednesdayGToggle").style.display = "block"
    }
  }

  const toggleThursdayG = () => {
    if(document.getElementById("thursdayGToggle").style.display = "none"){
      document.getElementById("thursdayGToggle").style.display = "block"
    }
  }

  const toggleFridayG = () => {
    if(document.getElementById("fridayGToggle").style.display = "none"){
      document.getElementById("fridayGToggle").style.display = "block"
    }
  }

  const toggleSundayL = () => {
    if(document.getElementById("sundayLToggle").style.display = "none"){
      document.getElementById("sundayLToggle").style.display = "block"
    }
  }

  const toggleMondayL = () => {
    if(document.getElementById("mondayLToggle").style.display = "none"){
      document.getElementById("mondayLToggle").style.display = "block"
    }
  }

  const toggleTuesdayL = () => {
    if(document.getElementById("tuesdayLToggle").style.display = "none"){
      document.getElementById("tuesdayLToggle").style.display = "block"
    }
  }

  const toggleWednesdayL = () => {
    if(document.getElementById("wednesdayLToggle").style.display = "none"){
      document.getElementById("wednesdayLToggle").style.display = "block"
    }
  }

  const toggleThursdayL = () => {
    if(document.getElementById("thursdayLToggle").style.display = "none"){
      document.getElementById("thursdayLToggle").style.display = "block"
    }
  }

  return (

    <div class="mainContent">
      <h1>Oglebay 2025 Signup</h1>
      <ul style={{width: "90%", marginBottom: "40px", marginLeft: "3%"}}>
        <li>Please sign-up with your tentative 2025 plans by 1 October 2024 so that we can properly book cottages and tee-times.</li>
        <li>In the event that we fill up, priority will be given to those with the highest Hall of Fame points followed by previous years' attendance.</li>
        <li>Type in your email address and check each day you plan to participate in golf and lodging; then click the "Sign Up" button</li>
        <li>If you make a mistake or need to change your golfing and / or lodging dates, please contact Mike Baumgartner</li>
      </ul>
      <div class="signupContainer">
        <div class="inputDiv" style={{width:"50%", margin: "0 auto"}}>
            <input class="attendeeInput" placeholder="Email Address" onChange={(e) =>{setInputName(e.target.value)}}/>
              <h3 id={'emailtext'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter an Email Address</h3>
              <h3 id={'emailduplicate'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
              <h5 id={'emailerror'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Email entered was not recognized. <br></br>
              If this was a mistake, contact Mike Baumgartner.</h5>
            <h3 id={'submitsuccess'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>You have been successfully added.</h3>
          </div>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <fieldset style={{width: "20%"}}>
            <legend>Golfing</legend>
            <div>
              <input type="checkbox" id="sundayG" name="sundayG" onChange={sundayGChange}/>
              <label for="sundayG">Sunday, 7 September</label>
            </div>
            <div>
              <input type="checkbox" id="mondayG" name="mondayG" onChange={mondayGChange}/>
              <label for="mondayG">Monday, 8 September</label>
            </div>
            <div>
              <input type="checkbox" id="tuesdayG" name="tuesdayG" onChange={tuesdayGChange}/>
              <label for="tuesdayG">Tuesday, 9 September</label>
            </div>
            <div>
              <input type="checkbox" id="wednesdayG" name="wednesdayG" onChange={wednesdayGChange}/>
              <label for="wednesdayG">Wednesday, 10 September</label>
            </div>
            <div>
              <input type="checkbox" id="thursdayG" name="thursdayG" onChange={thursdayGChange}/>
              <label for="thursdayG">Thursday, 11 September</label>
            </div>
            <div>
              <input type="checkbox" id="fridayG" name="fridayG" onChange={fridayGChange}/>
              <label for="fridayG">Friday, 12 September</label>
            </div>
          </fieldset>
          <fieldset style={{width: "20%"}}>
            <legend>Lodging</legend>
            <div>
              <input type="checkbox" id="sundayL" name="sundayL" onChange={sundayLChange}/>
              <label for="sundayL">Sunday, 7 September</label>
            </div>
            <div>
              <input type="checkbox" id="mondayL" name="mondayL" onChange={mondayLChange}/>
              <label for="mondayL">Monday, 8 September</label>
            </div>
            <div>
              <input type="checkbox" id="tuesdayL" name="tuesdayL" onChange={tuesdayLChange}/>
              <label for="tuesdayL">Tuesday, 9 September</label>
            </div>
            <div>
              <input type="checkbox" id="wednesdayL" name="wednesdayL" onChange={wednesdayLChange}/>
              <label for="wednesdayL">Wednesday, 10 September</label>
            </div>
            <div>
              <input type="checkbox" id="thursdayL" name="thursdayL" onChange={thursdayLChange}/>
              <label for="thursdayL">Thursday, 11 September</label>
            </div>
          </fieldset>
        </div>
        <div class="inputDiv" style={{marginTop: "40px", marginBottom: "40px"}}>
          <button  onClick={() =>{checkSignup()}}>Sign Up</button>
        </div>
      </div>
      <ul class="eventList" style={{width: "90%"}}>
        {teeTimes?.map((teetime, index) => (
            <li key={teetime.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{teetime.Name}</h2>
                  <h2>September 7-12</h2>
                </div>
                <h4>Attendees:</h4>
                {console.log(teetime)}
                <ul>
                  {teetime.attendees.map((person, index)=>(
                    <li key={index}>
                      <p>{person}</p>
                    </li>
                  ))}
                </ul>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h3>Golfing</h3>
                  <div style={{display: "flex", justifyContent: "space-around"}}>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Sunday: </b> {teetime.sundayGolf.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleSundayG()}}>See all attendees</button>
                      <ul id="sundayGToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.sundayGolf.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Monday: </b>{teetime.mondayGolf.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleMondayG()}}>See all attendees</button>
                      <ul id="mondayGToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.mondayGolf.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Tuesday: </b>{teetime.tuesdayGolf.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleTuesdayG()}}>See all attendees</button>
                      <ul id="tuesdayGToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.tuesdayGolf.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Wednesday: </b>{teetime.wednesdayGolf.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleWednesdayG()}}>See all attendees</button>
                      <ul id="wednesdayGToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.wednesdayGolf.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Thursday: </b>{teetime.thursdayGolf.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleThursdayG()}}>See all attendees</button>
                      <ul id="thursdayGToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.thursdayGolf.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Friday: </b>{teetime.fridayGolf.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleFridayG()}}>See all attendees</button>
                      <ul id="fridayGToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.fridayGolf.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                  </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", marginTop: "40px"}}>
                  <h3>Lodging</h3>
                  <div style={{display: "flex", justifyContent: "space-around"}}>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Sunday: </b> {teetime.sundayLodge.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleSundayL()}}>See all attendees</button>
                      <ul id="sundayLToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.sundayLodge.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Monday: </b>{teetime.mondayLodge.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleMondayL()}}>See all attendees</button>
                      <ul id="mondayLToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.mondayLodge.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Tuesday: </b>{teetime.tuesdayLodge.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleTuesdayL()}}>See all attendees</button>
                      <ul id="tuesdayLToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.tuesdayLodge.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Wednesday: </b>{teetime.wednesdayLodge.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleWednesdayL()}}>See all attendees</button>
                      <ul id="wednesdayLToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.wednesdayLodge.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                    <section style={{display:"flex", flexDirection: "column", textAlign: "center"}}>
                      <p><b>Thursday: </b>{teetime.thursdayLodge.length}</p>
                      <button class="chevronButton" onClick={() =>{toggleThursdayL()}}>See all attendees</button>
                      <ul id="thursdayLToggle" style={{display:"none", textAlign: "left"}}>
                        {teetime.thursdayLodge.map((person, index)=>(
                          <li key={index}>
                            <p>{person}</p>
                          </li>
                        ))}
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
    </div>
  );
};
  
export default Signup2025;