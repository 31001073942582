import React from "react";
  
const Gallery = () => {
  return (
    <div>
      {/* <iframe src="https://calendar.google.com/calendar/embed?src=fancytickler7%40gmail.com&ctz=America%2FNew_York"></iframe> */}
    </div>
  );
};



export default Gallery;