import React from 'react';
import { useState, useEffect } from 'react';
import { db } from "../firebase-config";
import { collection, getDocs, query} from "firebase/firestore"

  
const Home = () => {

  const bannerCollectionRef = collection(db, "Banner");
  const [bannerData, setBannerData] = useState([]);

  useEffect(() =>{
    const getBanner = async () => {
      const qb = query(bannerCollectionRef);
      const banners = await getDocs(qb);
      setBannerData(banners.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    getBanner().then(function(){
      console.log("get banner ran");
      console.log(bannerData);
    })

  }, []);

  return (
    <div class="marqueeDiv">
    <marquee class="bannerMessage">{bannerData[0]?.message}</marquee>
    <div class="homePageDiv">
      <div class="hpContent">
        <div class="welcomeDiv">
          <h2>Welcome to </h2>
          <div class="homeLogo"></div>
        </div>
        
        <p>Members of MBGolfers travel around NE Ohio and beyond to play some of the finest courses that Ohio, West Virginia, North Carolina 
          and Michigan have to offer. Our website provides information regarding planned courses to play, a schedule of upcoming play opportunities, 
          and a method to sign up for various MBGolfers events. <br></br>
          </p>
          <h3>We'll Make You Play </h3>
      </div>
      <div class="hpContent">
        <h3>Our Mission</h3>
        <ol>
          <li>
            Keep our group of senior (and near senior) golfers active
          </li>
          <li>
            Provide relief to spouses by getting their husbands out of the house 
          </li>
          <li>
            Provide spouses with a method to track where their husbands are on any given day
          </li>
          <li>
            Help to stimulate our local economies by keeping these golf courses financially healthy
          </li>
        </ol>
      </div>
    </div>
    </div>
  );
};
  
export default Home;