import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../../firebase-config";
import { collection, getDocs, updateDoc, doc, where, query, orderBy, arrayRemove, limit } from "firebase/firestore"
  
const Ogb2024Play = () => {

  const [usersList, setUsersList] = useState([]);
  const [teeTimes, setTeeTimes ] = useState([]);
  const slotList = [0, 1, 2, 3, 4];
  const [slotListState, setSlotListState] = useState([0, 1, 2, 3, 4, 5])
  const OgbTeetimeCollectionRef = collection(db, "Oglebay2024TeeTimes");
  const UsersCollectionRef = collection(db, "users");
  const [inputName, setInputName] = useState("");
  const startOfDay = new Date();
  startOfDay.setUTCHours(0,0,0,0);
  let eventTime;
  let nameCheck="";
  let nameSwitch=false;
  let timeVar;


  useEffect(() =>{
    const getUsers = async () => {
      const qw = query(UsersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data()})))
    }
    getUsers();
  }, [])

  useEffect(() =>{

    const getTeeTimes = async () =>{
      const q = query(OgbTeetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 
    getTeeTimes()

  }, []);

  const checkTeeTime = async (id, index, time) =>{
    let tempData=[];
    const getTeeTimes = async () =>{
      const q = query(OgbTeetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addUser(tempData[index].id, time, tempData[index].Slots["Players"], tempData[index].Slots[time], tempData[index], tempData[index].Slots[time].length, index)
    };
    getTeeTimes()
    console.log(teeTimes);
  }

  const checkForDelete = async (id, index, time) =>{
    let tempData=[];
    const getTeeTimes = async () =>{
      const q = query(OgbTeetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      removeUser(tempData[index].id, time, tempData[index].Slots["Players"], tempData[index].Slots[time], tempData[index], tempData[index].Slots[time].length)
    };
    getTeeTimes()
    console.log(teeTimes);
  }

  const addUser = async (id, time, players, teetimeGolfers, timeSlots, slotLength, index) =>{
    const teetimeDoc= doc(db, "Oglebay2024TeeTimes", id);   
    let shownName="" 
    if(slotLength<teeTimes[0].NumberPerTeeTime){
      if(inputName === ""){
        document.getElementById("text"+time).style.display = "block"
      }else{
        for(let i=0; i<usersList.length; i++){
          nameCheck = usersList[i].email;
          if(nameCheck === inputName.toLowerCase()){
            console.log("yes, name is " + usersList[i].name);
            nameSwitch=true;
            shownName= usersList[i].name;
          }
        }
        if(!players.includes(shownName)){
          if(nameSwitch===true){
            const teeTimePlayer = timeSlots;
            console.log(timeSlots)
            teeTimePlayer.Slots[time] = [...teetimeGolfers, shownName];
            teeTimePlayer.Slots["Players"] = [...players, shownName];
            await updateDoc(teetimeDoc, teeTimePlayer);
            document.getElementById("text"+time).style.display = "none";
            document.getElementById("duplicate"+time).style.display = "none";
            document.getElementById("success"+time).style.display = "block";
            document.getElementById(time+'input').value = "";
            window.location.reload();
          }
        }else{
          document.getElementById("duplicate"+time).style.display = "block";
        }
      }
    }else{
      document.getElementById("checkFailure"+time).style.display = "block";
    }
  }


  const removeUser = async (id, time, players, teetimeGolfers, timeSlots) => {
    let shownName="";
    let updatedList = [];
    let updatedPlayers = [];
    if(inputName === ""){
      document.getElementById("text"+time).style.display = "block"
    }else{
      for(let i=0; i<usersList.length; i++){
        nameCheck = usersList[i].email;
        if(nameCheck === inputName.toLowerCase()){
          console.log("yes, name is " + usersList[i].name);
          nameSwitch=true;
          shownName= usersList[i].name;
        }
      }
      if(players.includes(shownName)){
        if(nameSwitch===true){
          const updatedSlots = timeSlots
          updatedList = teetimeGolfers;
          var slotIndex = updatedList.indexOf(shownName)
          if(slotIndex !== -1){
            updatedList.splice(slotIndex, 1);
            updatedPlayers = players;
            var playersIndex = updatedPlayers.indexOf(shownName)
            if(playersIndex !== -1){
              updatedPlayers.splice(playersIndex, 1)
            }
            updatedSlots.Slots[time] = updatedList;
            updatedSlots.Slots["Players"] = updatedPlayers;
            const teetimeDoc = doc(db, "Oglebay2024TeeTimes", id);
            await updateDoc(teetimeDoc, updatedSlots);
            document.getElementById("text"+time).style.display = "none";
            document.getElementById("notPresent"+time).style.display = "none";
            document.getElementById("timeError"+time).style.display = "none";
            document.getElementById("removeSuccess"+time).style.display = "block";
            document.getElementById(time+'input').value = "";
            const getEvents = async () =>{
              const q = query(OgbTeetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"));
              const newData = await getDocs(q);
              setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
            };
            setInputName("");
            getEvents();
          }else{
            document.getElementById("timeError"+time).style.display = "block";
          }
        }
      }else{
        document.getElementById("notPresent"+time).style.display = "block";
      }
    }
    
    // await updateDoc(teetimeDoc, {
    //   Slots: arrayRemove(user)
    // })
  }


  return (

    <div class="mainContent">
      <h1>Oglebay 2024 Tee Times</h1>
      <ul style={{width: "90%", marginBottom: "40px", marginLeft: "3%"}}>
        <li>Tee-Times for Monday - Friday have been randomly generated.  Goal was to try to ensure each golfer played with as many others during the week as possible.</li>
        <li>Tee-Times for Sunday can be selected by the individual player.  If you are a Browns fan, try to go for the earlier tee-times so you are able to get back to 
          the cottage to watch the Browns.  If you are not a Browns fan, please go for the later tee-times as a courtesy for the Browns fans.</li>
      </ul>
      <ul class="eventList" style={{width: "90%"}}>
        {teeTimes?.map((teetime, index) => (
            <li key={teetime.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{teetime.Name}</h2>
                  <h2>{teetime.Location}</h2>
                  <h4>
                    {new Date(teetime.Date.seconds * 1000 + teetime.Date.nanoseconds / 1000000).toDateString()}<br></br>
                  </h4>
                </div>
                <h4>Tee Times:</h4>
                <div class="eventContent">
                  <ul class="timeSlotList">
                    {slotListState.map((value)=>(
                      <div class={'timeSlotDiv'+' '+teetime.Slots[value][0]}>
                        {teetime.Slots[value].map((slot, index)=>(
                          <li key={index}>
                            <p class={'timeSlotLI'+index}>{slot}</p>
                          </li>
                        ))}
                        {(() =>{
                          if ((teetime.NumberPerTeeTime > teetime.Slots[value].length)){
                            return (
                              <div style={{display: "flex", flexDirection: "column"}}>
                                <input id={value+'input'} class="attendeeInput" placeholder="Email" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={'text'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter your email</h3>
                                <h3 id={'timeError'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is signed up for a different time</h3>
                                <h3 id={'notPresent'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is not signed up</h3>
                                <h3 id={'duplicate'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <br></br>
                                <button class="sfxButton" onClick={() =>{checkTeeTime(teetime.id, index, value)}}>Sign Up</button>
                                <br></br>
                                <button class="sfxButton" id="red" onClick={() =>{checkForDelete(teetime.id, index, value)}}>Remove User</button>
                                <h3 id={'success'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                                <h3 id={'removeSuccess'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User has been removed</h3>
                                <h3 id={'checkFailure'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                              </div>
                            )
                          }else{
                          return(
                            <div style={{display: "flex", flexDirection: "column"}}>
                              <h3 style={{color: 'red', fontSize: '1.4em'}}>Tee Time is Full</h3>
                                <input id={value+'input'} class="attendeeInput" placeholder="Email" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={'text'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter your email</h3>
                                <h3 id={'timeError'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is signed up for a different time</h3>
                                <h3 id={'notPresent'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is not signed up</h3>
                                <h3 id={'duplicate'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <br></br>
                                <button class="sfxButton" id="red" onClick={() =>{checkForDelete(teetime.id, index, value)}}>Remove User</button>
                                <h3 id={'success'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                                <h3 id={'removeSuccess'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User has been removed</h3>
                                <h3 id={'checkFailure'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                            </div>
                          )
                          }
                        })()}
                      </div>
                    ))}
                  </ul>
                </div>
                <h4 class="notesText">Additional Notes:<br></br>{teetime.Notes}</h4>
              </div>
            </li>
          ))}
        </ul>
    </div>
  );
};
  
export default Ogb2024Play;