import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
// import axios from 'axios';

const Results = () => {

  const [participantNum, setPartNum] = useState(0);
  const [participants, setParticipants] = useState([]);
  const [name, setName] = useState('');
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // const myHeaders = new Headers();

      // myHeaders.append("Access-Control-Allow-Origin", '*');
      // myHeaders.append("Access-Control-Allow-Credentials", "true");

      const result = await fetch(URL, {
        method: 'GET',
        mode: 'cors',
        headers: {
          // 'Content-Type': 'application/json; charset=utf-8',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': '*',
          // 'Access-Control-Allow-Methods': '*'
        },
      })
      // const jsonData = await result;
      // console.log(jsonData);
      console.log(result);
    }
    fetchData();
  }, [])

  useEffect(() => {
    setPartNum(participants.length)
  }, [participants])

  return (
    <div class="mainContent">

      <div class="eventDiv">
        <div class="eventHeader">
          <h2>Placeholder Event Name</h2>
          <h2>Event Date- Time</h2>
        </div>
        <div class="eventContent">
          <section>
            <input id="playSignUp"
              value={name}
              label="Name"
              placeholder="Name"
              onChange={e => setName(e.target.value)}
            />
            <button id="signUpButton" onClick={() => {
              setName('');
              setParticipants([
                ...participants, { id: participants.length, name: name }
              ]);
              console.log(participants);
            }}>Sign Up</button>
          </section>
          <h4>Spots Available: {12 - participantNum}</h4>
          <ul>
            {participants.map(participant => (
              <li key={participant.id}>{participant.name}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Results;