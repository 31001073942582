import React, { useEffect, useState, useRef } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, addDoc, updateDoc, doc, where, query, orderBy, setDoc } from "firebase/firestore"


const Dropdown2024 = ({ placeHolder, options, isSearchable, coursesData }) => {

  const [showMenu, setShowMenu] = useState(false);
  const usersCollectionRef = collection(db, "users")
  const [usersList, setUsersList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef();
  const [inputName, setInputName] = useState("");
  const [layoutScore, setLayoutScore] = useState(0);
  const [teesScore, setTeesScore] = useState(0);
  const [fairwaysScore, setFairwaysScore] = useState(0);
  const [greensScore, setGreensScore] = useState(0);
  const [practiceScore, setPracticeScore] = useState(0);
  const reviewsCollectionRef = collection(db, "Reviews2024");
  const maxValue= 10;
  const minValue= 1;
  let nameCheck="";
  let courseExist=false;
  let courseID="";
  let nameSwitch=false;
  

  useEffect(() =>{
    const getUsers = async () => {
      const qw = query(usersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data()})))
    }

    getUsers();
  }, [])

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
        searchRef.current.focus();
    }
  }, [showMenu]);

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
        return options;
    }
    return options.filter((option) => 
    option.course.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
  };

  useEffect(() => {
    const handler = () => setShowMenu(false);

    window.addEventListener("click", handler);
    return () => {
        window.removeEventListener("click", handler);
    };
  });
  const handleInputclick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (selectedValue) {
        return selectedValue.course;
    }
    return placeHolder;
  };

  const onItemClick = (option) => {
    setSelectedValue(option);
    console.log(selectedValue)
  };


  const isSelected = (option) => {
    if(!selectedValue) {
        return false;
    }

    return selectedValue.course === option.course
  }

  const submitReview = async () => {
    // const reviewDoc = doc(db, "Courses")
    let reviewDoc = "";
    let shownName=""
    let courseName="";
    if(inputName === ""){
        console.log("Error, no email")
        document.getElementById("emailtext").style.display = "block";
    }else{
        if(!layoutScore=="" && !teesScore=="" && !fairwaysScore=="" && !greensScore=="" && !practiceScore=="" && !selectedValue==""){
          if(layoutScore<=10 && layoutScore>=1 && teesScore<=10 && teesScore>=1 && fairwaysScore<=10 && fairwaysScore>=1 && greensScore<=10 && greensScore>=1 && practiceScore<=10 && practiceScore>=1){

          
            console.log("all fields complete")
            for (let i=0; i<usersList.length; i++){
                nameCheck = usersList[i].email;
                if(nameCheck === inputName.toLowerCase()){
                    nameSwitch= true;
                    shownName=usersList[i].name
                }
            }
            if(nameSwitch===true){
                console.log("email is valid")
                for(let i=0; i<coursesData.length; i++){
                    if(coursesData[i].name === selectedValue.course){
                        console.log("course exists")
                        courseExist=true;
                        courseID=i;
                        console.log(coursesData[i])    
                        console.log("courses data: "+coursesData)            
                      }
                }
                if(courseExist===true){
                  const userList = coursesData[courseID].user
                  let reviewDoc = doc(db, "Reviews2024", coursesData[courseID].id)    
                  console.log(userList)
                  if(!userList.includes(shownName)){
                    const layout =  [...coursesData[courseID].layout, parseInt(layoutScore) ]
                    const courseCond = {fairways: [...coursesData[courseID].courseCond.fairways, parseInt(fairwaysScore)], 
                      greens: [...coursesData[courseID].courseCond.greens, parseInt(greensScore)],
                      tees: [...coursesData[courseID].courseCond.tees, parseInt(teesScore)]}
                    const practice = [...coursesData[courseID].practice, parseInt(practiceScore) ]
                    const user = [...coursesData[courseID].user, shownName]
                    await updateDoc(reviewDoc, {layout, courseCond, practice, user})
                    document.getElementById("submitsuccess").style.display = "block";
                    document.getElementById("emailduplicate").style.display = "none";
                    document.getElementById("emptyField").style.display = "none";
                    document.getElementById("emailtext").style.display = "none";
                  }else{
                    document.getElementById("emailduplicate").style.display = "block";
                  }
                  }else{
                    let courseName = selectedValue.course
                    await addDoc(reviewsCollectionRef, {name: `${courseName}`, layout: [layoutScore], courseCond: {fairways: [fairwaysScore], greens: [greensScore], tees: [teesScore]}, practice: [practiceScore], user: [shownName]})
                    document.getElementById("submitsuccess").style.display = "block";
                    document.getElementById("emailduplicate").style.display = "none";
                    document.getElementById("emptyField").style.display = "none";
                    document.getElementById("oobfield").style.display = "none";
                    document.getElementById("emailtext").style.display = "none";
                    window.location.reload();
                  }
            }
          }else{
            document.getElementById("oobfield").style.display = "block"
          }
        }else{
            document.getElementById("emptyField").style.display = "block";
        }
    }
  }

  return (
    <div className="dropdownContainer">
      <div onClick={handleInputclick} className="dropdownInput">
        <div className="dropdown-selected-value">{getDisplay()}</div>
        <div className="dropdown-tools"></div>
      </div>
      {showMenu && (
        <div className="dropdownMenu">
        {isSearchable && (
            <div className="searchBox">
                <input onChange={onSearch} value={searchValue} ref={searchRef} />
            </div>
        )}
        {getOptions().map((option) => (
            <div onClick={() => onItemClick(option)} key={option.course} className={`dropdownItem ${isSelected(option) && "selected"}`}>
                {option.course}
            </div>
        ))}
        </div>
      )}
      <div class="scores">
        <div class="indivScore">
          <label for="Layout">Layout</label>
            <input id="Layout" type="number" min={1} max={10} onChange={(e) =>{
                setLayoutScore(Math.round(e.target.value))
              }}/>
        </div>
        <div class="indivScore">
          <label for="Tees">Tees</label>
            <input id="Tees" type='number' min="1" max="10" onChange={(e) =>{
                setTeesScore(Math.round(e.target.value))
              }}/>
        </div>
        <div class="indivScore">
          <label for="Fairways">Fairways</label>
            <input id="Fairways" type='number' min="1" max="10" onChange={(e) =>{
                setFairwaysScore(Math.round(e.target.value))
              }}/>
        </div>
        <div class="indivScore">
          <label for="Greens">Greens</label>
            <input id="Greens" type='number' min="1" max="10" onChange={(e) =>{
                setGreensScore(Math.round(e.target.value))
              }}/>
        </div>
        <div class="indivScore">
          <label for="Practice">Practice Area</label>
            <input id="Practice" type='number' min="1" max="10" onChange={(e) =>{
                setPracticeScore(Math.round(e.target.value))
              }}/>
        </div>
      </div>
        
        <h3 id={'emptyField'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Enter a Value For All Fields</h3>

        <div class="inputDiv">
          <input class="attendeeInput" placeholder="Email Address" onChange={(e) =>{setInputName(e.target.value)}}/>
            <h3 id={'emailtext'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter an Email Address</h3>
            <h3 id={'emailduplicate'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
            <h3 id={'oobfield'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please enter scores between 1 and 10</h3>
            <h5 id={'emailerror'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Email entered was not recognized. <br></br>
            If this was a mistake, contact Mike Baumgartner.</h5>
          {/* <button  onClick={() =>{addParticipant()}}>Sign Up</button> */}
          <h3 id={'submitsuccess'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Review Submitted! Please Reload the page.</h3>
          <button onClick={() => {submitReview()}}>Submit Review</button>
        </div>

    </div>
  );
};

export default Dropdown2024;