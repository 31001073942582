import React from 'react';
import JamesHS from './OgbImages/JamesHeadshot2.jpg';
import GreggHS from './OgbImages/GreggHeadshot.jpg';
import DaveHS from './OgbImages/DaveHeadshot.jpg';
import JohnRHS from './OgbImages/JohnHeadshot.jpg';
import MikeHS from './OgbImages/MikeHeadshot.jpg';
import AlanHS from './OgbImages/AlanHeadshot.jpg';
import JohnHHS from './OgbImages/JohnHHeadshot2.jpg';
import DwightHS from './OgbImages/DwightHeadshot2.jpg';
import BobRHS from './OgbImages/BobRHeadshot.jpg';
import SteveHS from './OgbImages/SteveHeadshot.jpg';
  
const Hall_Of_Fame = () => {
  return (
    <div class="mainContent">
      <h1>Hall of Fame</h1>
      <p class="overviewText">
      The MBGolfers Invitational at Oglebay Hall of Fame originated in January 2023 after the completion of the 15th Annual event.  
      It is the highest honor that can be conferred to past attendees of the event.<br></br><br></br>
      </p>
      <h4> 
        Membership into the Hall of Fame is achieved by meeting any one of the following three (3) requirements:
      </h4>
      <ul class="reqList">
        <li>
          Founding Member of the Oglebay Invitational
        </li>
        <li>
          40 rounds of golf played at the Oglebay Invitational
        </li>
        <li>
          12 years of particiaption at the Oglebay Invitational
          <br></br>
          <br></br>
        </li>
        <li style={{listStyle:"none", textAlign:"center"}}><b>OR</b><br></br><br></br></li>
        <li>Added by unanimous vote of Hall of Fame current members</li>
      </ul>
      <h2 style={{textAlign: 'center', marginTop: '50px'}}> Hall of Fame Members</h2>
      <h3>2024 Inductees</h3>
      <ul class="hofMembers">
        <li>
          <div class="memberCard">
            <img src={BobRHS}></img>
            <h3>Bob Rogers</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Rounds of Golf - 41
            </p>
          </div>
        </li>
      </ul>
      <h3>2023 Inductees</h3>
      <ul class="hofMembers">
      <li>
          <div class="memberCard">
            <img src={DwightHS}></img>
            <h3>Dwight Baumgartner</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Rounds of Golf - 43<br></br>
              Founding Member
            </p>
          </div>
        </li>
      <li>
          <div class="memberCard">
            <img src={GreggHS}></img>
            <h3>Gregg Baumgartner</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Participation Years - 15<br></br>
              Rounds of Golf - 81<br></br>
              Founding Member
            </p>
          </div>
        </li>
        <li>
          <div class="memberCard">
            <img src={JamesHS}></img>
            <h3>James Baumgartner</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Participation Years - 13<br></br>
              Rounds of Golf - 71<br></br>
              Founding Member
            </p>
          </div>
        </li>
        <li>
          <div class="memberCard">
            <img src={MikeHS}></img>
            <h3>Mike Baumgartner</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Participation Years - 15<br></br>
              Rounds of Golf - 82<br></br>
              Founding Member
            </p>
          </div>
        </li>
        <li>
          <div class="memberCard">
            <img src={SteveHS}></img>
            <h3>Steve Baumgartner</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Participation Years - 12 <br></br>
              Rounds of Golf - 61 <br></br>
              Founding Member
            </p>
          </div>
        </li>
        <li>
          <div class="memberCard">
            <img src={JohnHHS}></img>
            <h3>John Harris</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Rounds of Golf - 44
            </p>
          </div>
        </li>
        <li>
          <div class="memberCard">
            <img src={JohnRHS}></img>
            <h3>John Rothlisberger</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Founding Member
            </p>
          </div>
        </li>
        <li>
          <div class="memberCard">
            <img src={DaveHS}></img>
            <h3>Dave Small</h3>
            <h5>Qualifications Met:</h5>
            <p>
              Participation Years - 12<br></br>
              Rounds of Golf - 42<br></br>
              Founding Member
            </p>
          </div>
        </li>
        <li>
          <div class="memberCard">
            <img src={AlanHS}></img>
            <h3>Alan Yoder</h3>
            <h5>Qualifications Met:</h5>
            <p>

              Founding Member
            </p>
          </div>
        </li>

      </ul>
    </div>
  );
};
  
export default Hall_Of_Fame;