import React from "react";
import { Route, Outlet} from "react-router-dom";
import Navbar from "../navbar";
import Navbar2 from "../ogbnavbar";

const Layout = () => {
  return (
    <>
    {(() => {
                      if (window.location.pathname.includes('/oglebay')){
                        return(
                          <div class="oglebayBackground">
                          <Navbar2 />
                          <Outlet />
                        </div>
                        )

                      }else{
                        if (window.location.pathname.includes('/sfx_golf')){
                          return(
                            <div class="backgroundImg">
                            <Outlet />
                          </div>
                          )
                        }else{
                          return(
                            <div class="backgroundImg">
                            <Navbar />
                            <Outlet />
                          </div>
                          )
                        }
                      }})()}
    <footer>
      {console.log(window.location.pathname)}
      <div class="footerContent">
        <div>
          For information or questions, contact <a href="mailto:mbgolfers1@gmail.com">Mike Baumgartner</a> 
        </div>
        <div>
          Website Developed by <a href="mailto:abaumg14@kent.edu">Alex Baumgartner</a> 
        </div>
      </div>
    </footer>
    </>
  );
};

export default Layout;