import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, updateDoc, doc, where, query, orderBy, arrayRemove, limit } from "firebase/firestore"
  
const SFXGolfLeague = () => {

  const [usersList, setUsersList] = useState([]);
  const [teeTimes, setTeeTimes ] = useState([]);
  const slotList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
  const teetimeCollectionRef = collection(db, "TeeTimes");
  const sfxUsersCollectionRef = collection(db, "sfxUsers");
  const [inputName, setInputName] = useState("");
  const startOfDay = new Date();
  startOfDay.setUTCHours(0,0,0,0);
  let eventTime;
  let nameCheck="";
  let nameSwitch=false;
  let timeVar;


  useEffect(() =>{
    const getSFXUsers = async () => {
      const qw = query(sfxUsersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data()})))
    }
    getSFXUsers();
  }, [])

  useEffect(() =>{

    const getTeeTimes = async () =>{
      const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"), limit(1));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 
    getTeeTimes()

  }, []);

  const checkTeeTime = async (time) =>{
    let tempData=[];
    const getTeeTimes = async () =>{
      const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"), limit(1));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addUser(tempData[0].id, time, tempData[0].Slots["Players"], tempData[0].Slots[time], tempData[0], tempData[0].Slots[time].length)
    };
    getTeeTimes()
    console.log(teeTimes);
  }

  const checkForDelete = async (time) =>{
    let tempData=[];
    const getTeeTimes = async () =>{
      const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "asc"), limit(1));
      const newData = await getDocs(q);
      setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      removeUser(tempData[0].id, time, tempData[0].Slots["Players"], tempData[0].Slots[time], tempData[0], tempData[0].Slots[time].length)
    };
    getTeeTimes()
    console.log(teeTimes);
  }

  const addUser = async (id, time, players, teetimeGolfers, timeSlots, slotLength) =>{
    const teetimeDoc= doc(db, "TeeTimes", id);    
    let shownName=""
    if(slotLength<teeTimes[0].NumberPerTeeTime){
      if(inputName === ""){
        document.getElementById("text"+time).style.display = "block"
      }else{
        for(let i=0; i<usersList.length; i++){
          nameCheck = usersList[i].phoneNumber;
          if(nameCheck === inputName){
            nameSwitch=true;
            shownName= usersList[i].name;
          }
        }if(!players.includes(shownName)){
          if(nameSwitch===true){
            const teeTimePlayer = timeSlots;
            teeTimePlayer.Slots[time] = [...teetimeGolfers, shownName];
            teeTimePlayer.Slots["Players"] = [...players, shownName];
            await updateDoc(teetimeDoc, teeTimePlayer);
            document.getElementById("text"+time).style.display = "none";
            document.getElementById("error"+time).style.display = "none";
            document.getElementById("duplicate"+time).style.display = "none";
            document.getElementById("success"+time).style.display = "block";
            document.getElementById(time+'input').value = "";
            window.location.reload();
          }else{
            document.getElementById("error"+time).style.display = "block";
          }
        }else{
          document.getElementById("duplicate"+time).style.display = "block";
        }
      }
    }else{
      document.getElementById("checkFailure"+time).style.display = "block";
    }
  }


  const removeUser = async (id, time, players, teetimeGolfers, timeSlots) => {

    let shownName="";
    let updatedList = [];
    let updatedPlayers = [];
    if(inputName === ""){
      document.getElementById("text"+time).style.display = "block"
    }else{
      for(let i=0; i<usersList.length; i++){
        nameCheck = usersList[i].phoneNumber;
        if(nameCheck === inputName){
          nameSwitch=true;
          shownName= usersList[i].name;
        }
      }if(players.includes(shownName)){
        if(nameSwitch===true){
          const updatedSlots = timeSlots
          updatedList = teetimeGolfers;
          var slotIndex = updatedList.indexOf(shownName)
          if(slotIndex !== -1){
            updatedList.splice(slotIndex, 1);
            updatedPlayers = players;
            var playersIndex = updatedPlayers.indexOf(shownName)
            if(playersIndex !== -1){
              updatedPlayers.splice(playersIndex, 1)
            }
            updatedSlots.Slots[time] = updatedList;
            updatedSlots.Slots["Players"] = updatedPlayers;
            const teetimeDoc = doc(db, "TeeTimes", id);
            await updateDoc(teetimeDoc, updatedSlots);
            document.getElementById("text"+time).style.display = "none";
            document.getElementById("error"+time).style.display = "none";
            document.getElementById("notPresent"+time).style.display = "none";
            document.getElementById("timeError"+time).style.display = "none";
            document.getElementById("removeSuccess"+time).style.display = "block";
            document.getElementById(time+'input').value = "";
            const getEvents = async () =>{
              const q = query(teetimeCollectionRef, where("Date", ">", startOfDay), orderBy("Date", "desc"), limit(1));
              const newData = await getDocs(q);
              setTeeTimes(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
            };
            setInputName("");
            getEvents();
          }else{
            document.getElementById("timeError"+time).style.display = "block";
          }
        }else{
          document.getElementById("error"+time).style.display = "block";
        }
      }else{
        document.getElementById("notPresent"+time).style.display = "block";
      }
    }
    
    // await updateDoc(teetimeDoc, {
    //   Slots: arrayRemove(user)
    // })
  }


  return (

    <div class="mainContent">
      <h1>Saint Francis Xavier Golf League</h1>
      <ul style={{width: "90%", marginBottom: "40px", marginLeft: "3%"}}>
        <li>Select the tee-time that you would like to play your match and enter your 10-digit phone number (numbers only, no spaces, dashes, dots, etc.) and click the Sign Up button for a tee-time</li>
        <li>Encourage your opponent to sign up as soon as possible as well (or you can sign them up using their phone number) so you are in the same foursome</li>
        <li>Those who have signed up for tee-times will have priority when teeing off on league night (i.e. if you didn't sign up for a tee time, you will need to wait for an opening on the tee sheet)</li>
        <li>If you need to cancel your tee-time, reenter your phone number in the appropriate block and click the Remove User button and it will be removed.</li>
      </ul>
      <ul class="eventList" style={{width: "90%"}}>
        {teeTimes?.map((teetime) => (
            <li key={teetime.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                  <h2>{teetime.Name}</h2>
                  <h2>{teetime.Location}</h2>
                  <h4>
                    {new Date(teetime.Date.seconds * 1000 + teetime.Date.nanoseconds / 1000000).toDateString()}<br></br>
                  </h4>
                </div>
                <h4>Tee Times:</h4>
                <div class="eventContent">
                  <ul class="timeSlotList">
                    {slotList.map((value)=>(
                      <div class="timeSlotDiv">
                        {teetime.Slots[value].map((slot, index)=>(
                          <li key={index}>
                            <p class={'timeSlotLI'+index}>{slot}</p>
                          </li>
                        ))}
                        {(() =>{
                          if ((teetime.NumberPerTeeTime > teetime.Slots[value].length)){
                            return (
                              <div style={{display: "flex", flexDirection: "column"}}>
                                <input id={value+'input'} class="attendeeInput" placeholder="Phone Number" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={'text'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Phone Number</h3>
                                <h3 id={'timeError'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is signed up for a different time</h3>
                                <h3 id={'notPresent'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is not signed up</h3>
                                <h3 id={'duplicate'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <h5 id={'error'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Phone Number entered was not recognized. <br></br>
                                If this was a mistake, contact Mike Baumgartner.</h5>
                                <br></br>
                                <button class="sfxButton" onClick={() =>{checkTeeTime(value)}}>Sign Up</button>
                                <br></br>
                                <button class="sfxButton" id="red" onClick={() =>{checkForDelete(value)}}>Remove User</button>
                                <h3 id={'success'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                                <h3 id={'removeSuccess'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User has been removed</h3>
                                <h3 id={'checkFailure'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                              </div>
                            )
                          }else{
                          return(
                            <div style={{display: "flex", flexDirection: "column"}}>
                              <h3 style={{color: 'red', fontSize: '1.4em'}}>Tee Time is Full</h3>
                                <input id={value+'input'} class="attendeeInput" placeholder="Phone Number" onChange={(e) =>{setInputName(e.target.value)}}/>
                                <h3 id={'text'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Phone Number</h3>
                                <h3 id={'timeError'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is signed up for a different time</h3>
                                <h3 id={'notPresent'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>User is not signed up</h3>
                                <h3 id={'duplicate'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                                <h5 id={'error'+value} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Phone Number entered was not recognized. <br></br>
                                If this was a mistake, contact Mike Baumgartner.</h5>
                                <br></br>
                                <button class="sfxButton" id="red" onClick={() =>{checkForDelete(value)}}>Remove User</button>
                                <h3 id={'success'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                                <h3 id={'removeSuccess'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>User has been removed</h3>
                                <h3 id={'checkFailure'+value} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                            </div>
                          )
                          }
                        })()}
                      </div>
                    ))}
                  </ul>
                </div>
                <h4 class="notesText">Additional Notes:<br></br>{teetime.Notes}</h4>
              </div>
            </li>
          ))}
        </ul>
    </div>
  );
};
  
export default SFXGolfLeague;