import ReviewDiv from './reviewdiv.js';
import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../firebase-config.js";
import { collection, getDocs, updateDoc, doc, where, query, orderBy } from "firebase/firestore"
import { usersData } from "./user_info.js";
import Papa from "papaparse";
import Dropdown from './dropdown.js';
  
const Reviews2023 = () => {

  const [coursesList, setCoursesList] = useState([])
  const [courses2023, setCourses2023] = useState([]);
  const reviewsCollectionRef = collection(db, "Reviews");
  const [tableArray, setTableArray] = useState([])
  let [errorText, setErrorText] = useState("");
  let nameCheck="";
  let nameSwitch=false;
  const bannerCollectionRef = collection(db, "Banner");
  const [bannerData, setBannerData] = useState([]);

  useEffect(() =>{
    const getBanner = async () => {
      const qb = query(bannerCollectionRef);
      const banners = await getDocs(qb);
      setBannerData(banners.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    getBanner().then(function(){
      console.log("get banner ran");
      console.log(bannerData);
    })

  }, []);

  const getCourses = async () =>{
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vSqFDtFYMD8iipm4W1o02-y-1Jr0EwpduihmLMxnNCKUAABRkJ_E_p_Zco7UczbNQ/pub?output=csv", {
      download: true,
      header: true,
      complete: (results) => {
  
        setCoursesList(results.data)
      },
    });
  }

  useEffect(() =>{
    getCourses();
    console.log("get courses ran")
  }, []);

  useEffect(() =>{
    console.log("course data updated")
    console.log(coursesList)
  }, [coursesList])

  useEffect(() =>{

    const getReviews = async () =>{
      const q = query(reviewsCollectionRef, orderBy("name", "asc"));
      const newData = await getDocs(q);
      setCourses2023(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 
 

    getReviews().then(function(){
      console.log("get courses function done")
    }); 

  }, []);

  useEffect(() => {
    console.log(courses2023)
  }, [courses2023]);

  useEffect(() => {
    setTableArray(courses2023.map(
      course => {
        return {
          name: course.name, users: course.user.length, layout: ((((course.layout.reduce((p,c,_,a) => p + c/a.length, 0)))).toFixed(2)),
          tees: (((course.courseCond.tees.reduce((p,c,_,a) => p + c/a.length, 0))).toFixed(2)), fairways: (((course.courseCond.fairways.reduce((p,c,_,a) => p + c/a.length, 0))).toFixed(2)),
          greens: (((course.courseCond.greens.reduce((p,c,_,a) => p + c/a.length, 0))).toFixed(2)), condTotal: (((((course.courseCond.tees.reduce((p,c,_,a) => p + c/a.length, 0))+(course.courseCond.fairways.reduce((p,c,_,a) => p + c/a.length, 0))+(course.courseCond.greens.reduce((p,c,_,a) => p + c/a.length, 0)))/3)).toFixed(2)),
          practice: (((course.practice.reduce((p,c,_,a) => p + c/a.length, 0))).toFixed(2)), 
          total:((((course.layout.reduce((p,c,_,a) => p + c/a.length, 0))+(course.courseCond.tees.reduce((p,c,_,a) => p + c/a.length, 0))+(course.courseCond.fairways.reduce((p,c,_,a) => p + c/a.length, 0))+(course.courseCond.greens.reduce((p,c,_,a) => p + c/a.length, 0))+(course.practice.reduce((p,c,_,a) => p + c/a.length, 0)))/5)).toFixed(2)
        }
      }
    ))
  }, [courses2023])

  useEffect(() => {
    console.log(tableArray)
  }, [tableArray])

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };

  const ReviewTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.products);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <table class="reviewTable">
        <thead>
          <tr>
            <th rowSpan={2} onClick={() => requestSort('name')}
                className={getClassNamesFor('name')}>
                Course Name
            </th>
            <th rowSpan={2}onClick={() => requestSort('users')}
                className={getClassNamesFor('users')}>
                Number of Ratings
            </th>
            <th rowSpan={2} onClick={() => requestSort('layout')}
                className={getClassNamesFor('layout')}>
                Layout
            </th>
            <th rowSpan={1} colSpan={4}>
              Course Conditions
            </th>
            <th rowSpan={2} onClick={() => requestSort('practice')}
                className={getClassNamesFor('practice')}>
                Practice Area
            </th>
            <th rowSpan={2} onClick={() => requestSort('total')}
                className={getClassNamesFor('total')}>
                Total Rating
            </th>
          </tr>
          <tr>
            <th rowSpan={2} onClick={() => requestSort('tees')}
                className={getClassNamesFor('tees')}>
                Tees
            </th>
            <th rowSpan={2} onClick={() => requestSort('fairways')}
                className={getClassNamesFor('fairways')}>
                Fairways
            </th>
            <th rowSpan={2} onClick={() => requestSort('greens')}
                className={getClassNamesFor('greens')}>
                Greens
            </th>
            <th rowSpan={2} onClick={() => requestSort('condTotal')}
                className={getClassNamesFor('condTotal')}>
                Conditions Total
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr>
              <td style={{width: "20%"}}>{item.name}</td>
              <td class="revScore" style={{width: "10%"}}>{item.users}</td>
              <td class="revScore">{item.layout}</td>
              <td class="revScore">{item.tees}</td>
              <td class="revScore">{item.fairways}</td>
              <td class="revScore">{item.greens}</td>
              <td class="revScore">{item.condTotal}</td>
              <td class="revScore">{item.practice}</td>
              <td class="revScore">{item.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div class="marqueeDiv">
    {/* <marquee class="bannerMessage">{bannerData[0]?.message}</marquee> */}
    <div class="mainContent">
      <h1>2023 Course Reviews</h1>
      {/* <h4>To provide a rating for a course you have played, select the course and provide ratings from 1 (lowest) - 10 (highest) for each category,<br></br>
      Enter your email address and submit your review.<br></br><br></br>
      Ratings for courses may be entered between 1 May – 31 October.<br></br> */}
      <h4>A summary table (sortable on each category) displaying all course ratings from 2023 is located below</h4>
      <br></br>
      <h4>To view the reviews for the 2024 season, click <a href="./reviews2024">here</a></h4>
      {/* <div reviewFields>
        <Dropdown isSearchable placeHolder="Select a Course" options={coursesList} coursesData={courses2023}/>
      </div> */}
      <ReviewTable products={tableArray} />
    </div>
    </div>
  );
}

export default Reviews2023;